<template>
  <section id="template-view">
    <b-row>
      <b-col>
        <b-overlay
          variant="transparent"
          :show="showTemplate"
        >
          <b-card
            no-body
            lazy
          >
            <b-card-header class="p-0 mb-2 mt-2">
              <b-card-title>
                {{ $t('templateConf') }}
              </b-card-title>
              <b-button
                class="save-button"
                variant="success"
                @click="validationFormInfo()"
              >
                {{ $t("dataGeneric.save") }}
              </b-button>
            </b-card-header>
            <b-form @submit.prevent="validationFormInfo">
              <validation-observer
                ref="menuRules"
                tag="form"
              >
                <b-row>
                  <b-col>
                    <validation-provider
                      v-slot="validationContext"
                      name="name"
                      rules="required"
                    >
                      <b-form-group :label="$t('name') + ' *'">
                        <b-form-input
                          id="name"
                          v-model="name"
                          :placeholder="$t('name')"
                          :state="validationContext.errors.length > 0 ? false : null
                          "
                          autocomplete="new-password"
                        />
                        <small class="text-danger">{{
                          validationMessage(validationContext)
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col>
                    <validation-provider
                      v-slot="validationContext"
                      name="key"
                      rules="required"
                    >
                      <b-form-group :label="$t('key') + ' *'">
                        <b-form-input
                          id="key"
                          v-model="key"
                          :placeholder="$t('key')"
                          :state="validationContext.errors.length > 0 ? false : null
                          "
                          autocomplete="new-password"
                        />
                        <small class="text-danger">{{
                          validationMessage(validationContext)
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <b-form-group :label="$t('menuLocation')">
                      <b-form-select
                        id="labelStatus"
                        v-model="menuLocationTv"
                        :label="$t('menuLocation')"
                      >
                        <b-form-select-option
                          v-for="keyMenu in Object.keys(MenuLocationOptions)"
                          :key="keyMenu"
                          :value="keyMenu"
                        >
                          {{ MenuLocationOptions[keyMenu] }}
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group :label="$t('widthSlider')">
                      <b-form-input
                        id="widthSlider"
                        v-model="widthSlider"
                        type="number"
                        :placeholder="$t('widthSlider')"
                        autocomplete="new-password"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group :label="$t('heightSlider')">
                      <b-form-input
                        id="heightSlider"
                        v-model="heightSlider"
                        type="number"
                        :placeholder="$t('heightSlider')"
                        autocomplete="new-password"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group :label="$t('widthSliderMobile')">
                      <b-form-input
                        id="widthSliderMobile"
                        v-model="widthSliderMobile"
                        type="number"
                        :placeholder="$t('widthSliderMobile')"
                        autocomplete="new-password"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group :label="$t('heightSliderMobile')">
                      <b-form-input
                        id="heightSliderMobile"
                        v-model="heightSliderMobile"
                        type="number"
                        :placeholder="$t('heightSliderMobile')"
                        autocomplete="new-password"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group :label="$t('imagenMuestra')">
                      <b-row class="mt-1">
                        <image-element
                          :image-origin="sampleImage"
                          :image-type="'sampleImage'"
                          :label="''"
                          md-length="6"
                          @saveImage="loadImage"
                        />
                      </b-row>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group :label="$t('dataGeneric.activeQ')">
                      <b-form-checkbox
                        id="isActive"
                        v-model="isActive"
                        :switch="true"
                      >
                        {{ $t('dataGeneric.active') }}
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-observer>
            </b-form>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </section>
</template>

<script>

import {
  messageError, showToast,
} from '@/store/functions'
import { required } from '@validations'
import '@core/assets/fonts/icons/css/all.css'
import '@core/assets/fonts/icons/scss/fontawesome.scss'
import {
  BForm,
  BRow,
  BCol,
  BFormCheckbox,
  BCardHeader,
  BCardTitle,
  BFormInput,
  BFormSelect,
  BFormSelectOption,
  BButton,
  BFormGroup,
  BCard,
  BOverlay,
} from 'bootstrap-vue'
import axios from '@axios'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { getUserData } from '@/auth/utils'
import ImageElement from '@/views/components/ImageElement.vue'

const fileError = require('@/assets/images/backend/file-error.png')
const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    BOverlay,
    BCardHeader,
    BCardTitle,
    BFormGroup,
    ValidationProvider,
    BFormSelectOption,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BFormCheckbox,
    BFormInput,
    BFormSelect,
    ImageElement,
    BButton,
    BCard,
  },
  directives: {
    Ripple,
  },
  props: {

    createBool: { default: false },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      key: '',
      MenuLocationOptions: {
        TOP: this.$t('top'),
        LEF: this.$t('left'),
      },
      sampleImage: '',
      required,
      menuLocationTv: 'TOP',
      isActive: false,
      name: null,
      userData: getUserData(),
      options: [
        { value: 'CON', text: 'Media' },
        { value: 'FIL', text: 'File' },
      ],
      showTemplate: false,
      headers: {},
      allTemplates: [],
      widthSlider: 0,
      widthSliderMobile: 0,
      heightSlider: 0,
      heightSliderMobile: 0,
    }
  },

  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) { }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    if (this.id) this.getTemplateById()
  },
  methods: {
    loadImage(item) {
      const { data, type } = item

      switch (type) {
        case 'sampleImage':
          this.sampleImage = data
          break

        default:
          break
      }
    },
    errorImg(e) {
      e.target.src = fileError
    },

    hideModal() {
      this.$refs['my-modal'].hide()
    },
    getTemplateById() {
      this.showTemplate = true
      const { headers } = this
      axios
        .post('', {
          query: `
           query{
            allTemplates(id:"${this.id}") {
              edges {
                node {
                  id
                  name
                  key
                  menuLocationTv
                  isActive
                  sampleImage
                  widthSlider
                  widthSliderMobile
                  heightSlider
                  heightSliderMobile
                }
              }
            }
          } 
          `,
        }, { headers })
        .then(res => {
          messageError(res, this)
          const item = res.data.data.allTemplates.edges[0].node

          this.name = item.name
          this.key = item.key
          this.menuLocationTv = item.menuLocationTv
          this.sampleImage = item.sampleImage
          this.widthSlider = item.widthSlider
          this.widthSliderMobile = item.widthSliderMobile
          this.heightSlider = item.heightSlider
          this.heightSliderMobile = item.heightSliderMobile

          this.isActive = item.isActive

          this.showTemplate = false
        })
    },
    addTemplate() {
      this.showTemplate = true
      const { headers } = this

      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()
      const query = `
        mutation{
          createTemplates(input:{            
            name:"${this.name}",
            key:"${this.key}",
            menuLocationTv:${this.menuLocationTv},
            widthSlider:${this.widthSlider},
            widthSliderMobile:${this.widthSliderMobile},
            heightSlider:${this.heightSlider},
            heightSliderMobile:${this.heightSliderMobile},
            isActive:${this.isActive},
            }){
              templatesClient{
                id
              }
            }
          }        
          `
      data.append('query', query)
      data.append('sample_image', this.sampleImage)

      axios
        .post('', data, { headers }, config)
        .then(res => {
          messageError(res, this)
          showToast(this.$t('success'), 1, this)
          this.$emit('refresh')
        })
        .catch(() => {
          this.showTemplate = false

          showToast(this.$t('error'), 2, this)
        })
    },
    updateTemplate(id) {
      this.showTemplate = true
      const { headers } = this
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const data = new FormData()
      const objectVariables = []
      objectVariables.push(
        { title: 'key', value: this.key, type: 'String' },
        { title: 'name', value: this.name, type: 'String' },
        { title: 'widthSlider', value: this.widthSlider, type: 'Int' },
        { title: 'widthSliderMobile', value: this.widthSliderMobile, type: 'Int' },
        { title: 'heightSlider', value: this.heightSlider, type: 'Int' },
        { title: 'heightSliderMobile', value: this.heightSliderMobile, type: 'Int' },
        { title: 'menuLocationTv', value: this.menuLocationTv, type: 'UsersTemplatesClientMenuLocationTvChoices' },
        { title: 'isActive', value: this.isActive, type: 'Boolean' },
      )
      const variables = {}
      let mutation = 'mutation('
      let query = `{
        updateTemplates(id:"${id}",input:{`
      objectVariables.forEach(element => {
        variables[element.title] = element.value
        mutation += `$${element.title}:${element.type},\n`
        query += `${element.title}:$${element.title},\n`
      })
      mutation += ')'
      query += `}){
        templatesClient{
                id
              }
        }
      }`
      query = mutation + query
      data.append('query', query)
      data.append('variables', JSON.stringify(variables))
      data.append('sample_image', this.sampleImage)

      axios
        .post('', data, { headers }, config)
        .then(res => {
          messageError(res, this)

          showToast(this.$t('success'), 1, this)
          this.$emit('refresh')
        })
        .catch(res => {
          console.log(res)
          this.showTemplate = false
          showToast(this.$t('error'), 2, this)
        })
    },
    actionTemplate() {
      if (this.createBool) { this.addTemplate() } else { this.updateTemplate(this.id) }
    },

    buildImageUrl(folder) {
      return folder == null || folder.length === 0
        ? noCover
        : folder
    },

    validationFormInfo() {
      return new Promise(resolve => {
        this.$refs.menuRules.validate().then(success => {
          if (success) {
            resolve(true)
            this.actionTemplate()
          }
        })
      })
    },

    validationMessage(data) {
      const keyContent = Object.keys(data.failedRules)
      if (keyContent.includes('required')) {
        return this.$t('required')
      }
      return ''
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

#template-view .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#template-view .card-body h4 {
  font-size: 1.286rem !important;
}

#template-view .col-form-label {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

#template-view .fly-image-input {
  max-width: 100%;
}

#template-view .base-image-input {
  display: block;

  background-size: cover;
  background-position: center center;
}

#template-view .base-color-input {
  display: block;
  border-radius: 15px;
  border: solid 1px;
  border-color: white;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#template-view .base-card-input {
  display: block;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

#template-view .base-image-inputIm {
  display: block;

  width: 35vh;
  height: 20vh;
  margin: auto;
  object-fit: contain;
  -webkit-mask-size: contain;
}

#template-view .div {
  display: inline-block;
}

#template-view .placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

#template-view .placeholder:hover {
  background: #e0e0e0;
}

#template-view .file-input {
  display: none;
}

#template-view .custom-control-label {
  font-size: 15px;
}

#template-view .scroll {
  overflow: hidden;
  flex-wrap: nowrap !important;

}

#template-view .list {
  overflow: hidden;
  overflow-y: scroll;
  height: 380px;
}

#template-view ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: none;
}

#template-view .contain {
  object-fit: contain;
  cursor: pointer;
}

#template-view .active-info {
  display: flex;
  align-items: center;
  width: 70%;
  font-weight: bold;
  color: aquamarine;
  font-size: smaller;
}

#template-view .dropContainer {
  border: 3px dashed;
}
</style>
